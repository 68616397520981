@import "./fonts/icoMoon/style.css";
@import "./styles/breakpoints";
@import "./fonts/fonts";
@import "./styles/colors";
@import "./styles/grid";
@import "./styles/typography";
@import "./styles/components";
@import "./styles/margin";

html {
  body {
    margin: 0;
    color: var(--text-default);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    line-height: unset;
    overflow-y: scroll;
    background-color: #F6F8F9;
  }
}

*, ::after, ::before {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

img, video {
  max-width: 100%;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  footer {
    margin-top: auto;
  }
}

.red-none{
    color: var(--error);
}
.green-yes{
    color: var(--success);
}
.mr-20{
    padding-right: 20px;
}

.link-open{
    cursor: pointer;
    transition: .3s;
    color: var(--accent);
    &:hover{
        color: var(--accent-hover);
    }
}
.link-accent{
    color: var(--accent);
}

.mr-10{
    margin-right: 10px;
}
.td-span{
    font-size: 14px;
    padding: 7px 16px;
    line-height: 20px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 4px;

    img{
        width: 22px;
        height: 22px;
        filter: grayscale(1) brightness(1000%);
    }
}
.indication-table{
    width: fit-content;
}
.back-white{
    background-color: #ffff;
    border-radius: 16px;
    border: 2px solid #2B93FA;
    margin-bottom: 8px;
    padding-bottom: 8px;
}

.color-accent{
    color: var(--accent) !important;
 }

 .wd-10{
    width: 5px;
 }

.icon-copy{
    margin-left: 5px;
    cursor: pointer;
    &:hover{
        color: var(--accent) !important;
    }
}
.ban-span{
    .td-span{
        width: fit-content;
    }
}
.ant-carousel .slick-slider .slick-dots li button{
    background: var(--accent);
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.ant-carousel .slick-slider .slick-dots li.slick-active button{
    background: var(--accent);
}
.ant-carousel .slick-slider .slick-prev, .ant-carousel .slick-slider .slick-next{
    color: var(--accent);
}
.ant-carousel .slick-next::before, .ant-carousel .slick-prev::before{
    font-size: 24px;
}
.ant-carousel .slick-slider img{
    width: 500px;
}
.ant-carousel .slick-prev:hover::before, .ant-carousel .slick-next:hover::before, .ant-carousel .slick-prev:focus::before, .ant-carousel .slick-next:focus::before{
    color: var(--accent);
}
.icon-noun-picture-1166736-1-1{
    cursor: pointer;
}
.ant-carousel{
    width: 500px;
}
.btn-outline{
    font-size: 14px;
    color: #ED7E52;
    transition: .2s;
    font-family: inherit;
    -webkit-appearance: none;
    border: 2px solid #ED7E52;
    cursor: pointer;
    border-radius: 8px;
    background: #fff;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    min-width: 120px;
    
    i {
      font-size: 20px;
      
      &:first-child {
        margin-right: 8px;
      }
      
      &:last-child {
        margin-left: 8px;
      }
    }
    &:hover {
        opacity: .8;
    }

}
.dropdown-list{
    .dropdown-list-ul li{
        font-size: 14px;
        padding: 4px 0;
        cursor: pointer;
        transition: .3s;

        &:hover{
            color: var(--accent);
        }
    }
}